// extracted by mini-css-extract-plugin
var _1 = "_rIxLbLogBvyruLy2ORY";
var _2 = "uyhhqEvQfHx2ZwjgZabE";
var _3 = "zu3CbzhhslKp66U8PTVv";
var _4 = "UYprvpLxmttWiSHPpjZA";
var _5 = "GR5NpyTsK37VCsiW5OmI";
var _6 = "FZzk4mZZQSSDy0QbdJqo";
var _7 = "pLzrJ1vyV19q8GeKGo6z";
var _8 = "vUbQmKt0VMBNG8ldJgkn";
export { _1 as "capitalCallTimer", _2 as "navTitle", _3 as "orange", _4 as "small", _5 as "tile", _6 as "tileLabel", _7 as "tileValue", _8 as "timer" }
