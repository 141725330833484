import { Table, Tooltip } from '@equitymultiple/react-eui';
import { Investment } from 'containers/Portfolio/types';
import React from 'react';

import utils from '../../../../utilities/utils';
import { tableHeaders, tooltips } from '../../constants';
import * as styles from '../../containers/AccountShared.module.scss';
import {
  formatPerformanceStatus,
  getActiveInvestmentNameCell
} from '../../helpers';

interface Props {
  investments: Investment[];
  loading: boolean;
  timePeriod: 'Total' | 'YTD';
}

const getColumnHeaders = () => [
  tableHeaders.investment,
  tableHeaders.performanceStatus,
  tableHeaders.investmentAmount,
  tableHeaders.dateInvested,
  <span key="exit-date" className={styles.infoIconWrap}>
    <span className={styles.th}>Exit Date (Anticipated)</span>
    <Tooltip
      className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
      infoIcon
      tooltipContent={tooltips.exitDateAnticipated}
      placement="top"
    />
  </span>,
  <span key="projected-return" className={styles.infoIconWrap}>
    <span className={styles.th}>Projected Return</span>
    <Tooltip
      className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
      infoIcon
      tooltipContent={tooltips.rateOfReturn}
      placement="top"
    />
  </span>,
  tableHeaders.distributions,
  ''
];

const getRows = (investments, timePeriod) => {
  if (investments) {
    let totalAmount = 0;
    let totalDistributions = 0;

    const rows = investments.map(investment => {
      const performanceStatus = investment.offering.performance_status;
      const amount = parseFloat(investment.amount);
      let distributions = parseFloat(investment.distributions);

      if (timePeriod === 'YTD') {
        distributions = parseFloat(investment.ytd_distributions);
      }

      totalAmount += amount;
      totalDistributions += distributions;

      let projectedReturn = investment.offering.irr;
      if (parseFloat(projectedReturn) < 0) projectedReturn = '-';

      let tooltipContent = tooltips.performanceStatuses[performanceStatus];
      if (performanceStatus === 'nonperforming')
        tooltipContent = tooltips.growPerformanceStatuses.nonperforming;

      return {
        cells: [
          getActiveInvestmentNameCell(investment),
          <span key="performance-status" className={styles.infoIconWrap}>
            {formatPerformanceStatus(investment.offering.performance_status)}
            <Tooltip
              className={`info-icon-margin-left ${styles.infoIconMarginLeft}`}
              infoIcon
              tooltipContent={tooltipContent}
              placement="top"
            />
          </span>,
          utils.formatCurrency(amount, 0),
          utils.dateFormat(investment.date_invested),
          utils.dateFormat(investment.offering.matures_on),
          <span key="projectedReturn" data-testid="projectedReturn">
            {projectedReturn}
          </span>,
          utils.formatCurrency(distributions, 2),
          <i key="icon" className="fa fa-chevron-right" />
        ],
        className: styles.rowLink,
        link: `/portfolio/account/${investment.investment_account_id}/investment/${investment.id}`
      };
    });

    rows.push({
      cells: [
        'Total',
        '',
        utils.formatCurrency(totalAmount, 0),
        '',
        '',
        '',
        utils.formatCurrency(totalDistributions, 2),
        ''
      ]
    });

    return rows;
  }

  return [];
};

const GrowInvestments = ({ investments, loading, timePeriod }: Props) => {
  return (
    <Table
      allowSorting={[0]}
      columnHeaders={getColumnHeaders()}
      loading={loading}
      rows={getRows(investments, timePeriod)}
      totalRow
      className={styles.table}
    />
  );
};

export default GrowInvestments;
