import {
  Button,
  Checkbox,
  ErrorLabel,
  Input,
  RadioButton
} from '@equitymultiple/react-eui';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Investment } from 'types/api/investment';
import utils from 'utilities/utils';

import * as sharedStyles from '../CapitalCallShared.module.scss';
import { CapitalCallOffering } from '../types';
import * as styles from './CapitalCallInterest.module.scss';

interface Props {
  dateInvested: string;
  investment: Investment;
  offering: CapitalCallOffering;
  onSubmit: (willOpt: boolean) => void;
  optOut: boolean;
  sending: boolean;
  transaction: {
    amount: string;
  };
}

const CapitalCallInterest = ({
  dateInvested,
  offering,
  investment,
  onSubmit,
  optOut,
  sending,
  transaction
}: Props) => {
  const [acceptedDisclaimer, setAcceptedDisclaimer] = useState(false);
  const [optIn, setOptIn] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (optOut) {
      setOptIn(false);
    }
  }, [optOut]);

  useEffect(() => {
    if (acceptedDisclaimer) setError(null);
  }, [acceptedDisclaimer]);

  const handleCheckClick = () => {
    setAcceptedDisclaimer(!acceptedDisclaimer);
  };

  const handleOptIn = () => {
    setOptIn(true);
  };

  const handleOptOut = () => {
    setOptIn(false);
  };

  const handleSubmit = () => {
    setError(null);

    if (optIn && !acceptedDisclaimer)
      setError(
        'You must confirm that you understand what the capital call is.'
      );
    else onSubmit(optIn);
  };

  const ccAmount = parseFloat(transaction.amount);

  return (
    <div>
      <div
        className={styles.hero}
        style={{ backgroundImage: `url(${offering.medium_banner_url})` }}
      >
        <div className={styles.textWrap}>
          <h1>Capital Call</h1>
          <div className={styles.date}>
            Original Investment on {utils.dateFormat(dateInvested)}
          </div>
          <Input
            label="Amount (Pro Rata)"
            value={utils.formatCurrency(ccAmount)}
            onBlur={() => {}}
            disabled
          />
        </div>
      </div>
      <div className={styles.info}>
        <h3 className="margin-xx">What is a Capital Call?</h3>
        <p>
          For a wide variety of reasons, real estate projects can require
          additional cash in order to pay for unexpected costs. In some cases,
          the Sponsor or Lender that EquityMultiple investors are investing
          alongside will have the right to request additional investment from
          investors through a capital call. While the circumstances and
          conditions of such capital call will depend on both the structure of
          the investment (i.e., equity vs debt) and particularities of the
          transaction, certain features are typically consistent. Investors will
          be asked to contribute their pro rata share (based upon their
          percentage interest in the investment at the time of such request) of
          such capital call. Investors may decline to participate in the capital
          call but may ultimately see their percentage ownership in the overall
          project diluted, sometimes at a penalty rate. In some cases, capital
          calls can increase the value of the project and, therefore, your
          investment and, in other cases, they are done to help minimize the
          potential for loss. It is important to understand the rationale for
          the capital call and the value it aims to create or preserve.
        </p>
        <Row>
          <Col md={4} className={sharedStyles.valueBox}>
            <div className="textLabel">Original Investment</div>
            <p>{utils.formatCurrency(investment.amount)}</p>
          </Col>
          <Col md={4} className={sharedStyles.valueBox}>
            <div className="textLabel">Capital Call Amount</div>
            <p>{utils.formatCurrency(ccAmount)}</p>
          </Col>
          <Col md={4} className={sharedStyles.valueBox}>
            <div className="textLabel">New Investment</div>
            <p>
              {utils.formatCurrency(parseFloat(investment.amount) + ccAmount)}
            </p>
          </Col>
        </Row>
        <div className={styles.radioDescription}>
          <h4>Reasons to participate</h4>
          <ul>
            <li>Maintain same ownership percentage as a limited partner</li>
            <li>Increase your original investment</li>
            <li>
              Help increase or protect the current value of your investment
            </li>
          </ul>
        </div>
        <div className={styles.radioDescription}>
          <h4>If you decline...</h4>
          <ul>
            <li>Your ownership percentage will be diluted</li>
          </ul>
        </div>
        <div className="margin-top-xxx">
          <RadioButton
            label="Yes, I'll fund"
            name="cc-optin"
            id="cc-optin-yes"
            onChange={handleOptIn}
            checked={optIn}
          />
          <RadioButton
            label="No, don't fund Capital Call"
            name="cc-optin"
            id="cc-optin-no"
            onChange={handleOptOut}
            checked={!optIn}
          />
        </div>
        <div className={sharedStyles.submitBox}>
          {optIn && (
            <div className={styles.interestDisclaimerWrap}>
              <Checkbox
                id="disclaimer"
                data-testid="disclaimer"
                name="disclaimer"
                onChange={handleCheckClick}
                checked={acceptedDisclaimer}
                label="I understand what the Capital Call is and what is requested of me. I understand the update to the budget and business plan associated with the capital call, acknowledge that the additional funds may not produce the intended results for my existing or new investment and reaffirm my understanding of the risks of this capital call and the investment generally, as originally set forth in the Investor Packet and otherwise."
              />
            </div>
          )}
          {error && (
            <ErrorLabel message={error} className={sharedStyles.errorMessage} />
          )}
          <Button
            variant="orange"
            className={sharedStyles.submitButton}
            loading={sending}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CapitalCallInterest;
