import { Button, Card, EMLoadingIcon } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  createAdditionalInterest,
  getAdditionalAllocation,
  getUserCapitalCallsForInvestment,
  optIn,
  optOut
} from 'redux/actions/capital-call';
import { loadInvestment } from 'redux/actions/investments';
import { loadClosing } from 'redux/actions/offerings';
import { Closing } from 'types/api/closing';
import { Investment } from 'types/api/investment';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import { handleErrorResponse } from 'utilities/errorHandlers';
import humane from 'utilities/humane';

import InvestmentStatus from '../../components/InvestmentStatus/InvestmentStatus';
import * as styles from './CapitalCall.module.scss';
import CapitalCallAllocation from './CapitalCallAllocation/CapitalCallAllocation';
import CapitalCallInterest from './CapitalCallInterest/CapitalCallInterest';
import { CapitalCallOffering } from './types';

type Params = {
  closing_id: string;
  investment_id: string;
  offering_id: string;
};

interface Props {
  closing: Closing;
  dispatch: Dispatch;
  investment?: Investment;
  loading?: boolean;
  match: {
    params: Params;
    path: string;
  };
  sending?: boolean;
  user?: User;
}

const CapitalCall = ({
  dispatch,
  closing,
  investment,
  match: { params, path },
  loading,
  sending,
  user
}: Props) => {
  const [step, setStep] = useState(1);
  const [maxAdditionalAllocation, setMaxAdditionalAllocation] = useState(null);
  const [transaction, setTransaction] = useState(null);

  const routeToFundPage = routingTransaction => {
    history.push(
      `/invest/${params.closing_id}/investment/${params.investment_id}/capital_call/${routingTransaction.id}/fund`
    );
  };

  useEffect(() => {
    document.title = 'Capital Call | EquityMultiple';

    if (
      path ===
      '/invest/:closing_id/investment/:investment_id/capital_call/allocation'
    )
      setStep(2);

    dispatch(getUserCapitalCallsForInvestment(params.investment_id))
      .then(res => {
        if (res.length > 0) {
          res.forEach(ccTransaction => {
            if (ccTransaction.investment_id === parseInt(params.investment_id))
              if (
                ccTransaction.status === 'posted' ||
                ccTransaction.status === 'pending'
              ) {
                // Investor should not be allowed to reneter the capital call flow if they have already submitted payment
                history.push(
                  `/invest/${params.closing_id}/investment/${params.investment_id}/confirm`
                );
              } else if (ccTransaction.status === 'failed') {
                // Investor should only be allowed to update their payment method on the Fund step, they will not be able to make other adjustments
                routeToFundPage(ccTransaction);
              } else {
                setTransaction(ccTransaction);
              }
          });
        }
      })
      .catch(error => handleErrorResponse(error));
    dispatch(loadClosing(params.closing_id)).catch(error =>
      handleErrorResponse(error)
    );
    dispatch(loadInvestment(params.investment_id)).catch(error =>
      handleErrorResponse(error)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      path ===
        '/invest/:closing_id/investment/:investment_id/capital_call/interest' &&
      step !== 1
    )
      setStep(1);
    if (
      path ===
        '/invest/:closing_id/investment/:investment_id/capital_call/thanks' &&
      step !== 3
    )
      setStep(3);
  }, [step, path]);

  useEffect(() => {
    if (step === 3) window.scrollTo(0, 0);
  }, [step]);

  const onInterestSubmit = willOpt => {
    if (willOpt) {
      dispatch(optIn(params.investment_id)).then(() => {
        history.push(
          `/invest/${params.closing_id}/investment/${params.investment_id}/capital_call/allocation`
        );
        setStep(2);
      });
    } else {
      dispatch(optOut(params.investment_id)).then(() => {
        history.push(
          `/invest/${params.closing_id}/investment/${params.investment_id}/capital_call/thanks`
        );
        setStep(3);
      });
    }
  };

  const onAllocationMount = () => {
    if (maxAdditionalAllocation === null) {
      dispatch(getAdditionalAllocation(params.investment_id))
        .then(res => {
          if (res.total) {
            const total = parseFloat(res.total);
            setMaxAdditionalAllocation(total);
          } else {
            setMaxAdditionalAllocation(0);
          }
        })
        .catch(error => {
          humane.error(error.body.message);
        });
    }
  };

  const onAllocationSubmit = amount => {
    const values = {
      amount,
      payment_method: 'ACH',
      effective_date: transaction.effective_date
    };
    if (amount !== 0) {
      dispatch(createAdditionalInterest(investment.id, values))
        .then(() => {
          routeToFundPage(transaction);
        })
        .catch(res => {
          humane.error(`${res.status_code} - ${res.body.error}`);
        });
    } else {
      routeToFundPage(transaction);
    }
  };

  const handleBackClick = e => {
    e.preventDefault();
    history.push(
      `/invest/${params.closing_id}/investment/${params.investment_id}/capital_call/interest`
    );
    setStep(1);
  };

  const offering: CapitalCallOffering = {
    title: investment?.offering.title,
    id: investment?.offering_id,
    medium_banner_url: investment?.offering.medium_banner_url
  };

  return !investment || !transaction || loading ? (
    <EMLoadingIcon />
  ) : (
    <div className={styles.capitalCall}>
      <Container className="container-narrow">
        <h2>
          {investment.offering.title} <span>Capital Call</span>
        </h2>
        <Card>
          {step !== 3 && (
            <InvestmentStatus
              investment={investment}
              capitalCallTransaction={transaction}
              closing={closing}
              step={step}
              dateInvested={transaction.date_invested}
            />
          )}
          {step === 1 && (
            <CapitalCallInterest
              dateInvested={transaction.date_invested}
              investment={investment}
              offering={offering}
              optOut={!investment.capital_call_opt_in}
              onSubmit={onInterestSubmit}
              sending={sending}
              transaction={transaction}
            />
          )}
          {step === 2 && (
            <CapitalCallAllocation
              investment={investment}
              maxAmount={maxAdditionalAllocation}
              onMount={onAllocationMount}
              onSubmit={onAllocationSubmit}
              sending={sending}
              transaction={transaction}
            />
          )}
          {step === 3 && (
            <>
              <h2>Thank you!</h2>
              <p className="margin-xxx">
                We appreciate your involvement in this offering and will
                continue to provide you with updates as the project moves
                forward.
              </p>
              <Button
                style={{
                  width: '160px'
                }}
                wrapper={<Link to="/" />}
                className="float-right"
              >
                Done
              </Button>
              <button
                className={`${styles.back} float-right`}
                onClick={handleBackClick}
                type="button"
              >
                Back
              </button>
            </>
          )}
          {step !== 3 && (
            <div className={styles.contactBox}>
              <h3>Have Questions?</h3>
              <p>
                <a
                  href="mailto:contact@equitymultiple.com"
                  className="text-link"
                >
                  Email
                </a>{' '}
                or{' '}
                <a
                  href={user.investor_profile.representative_meeting_link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-link"
                >
                  Schedule a one on one call
                </a>{' '}
                with our real estate professionals.
              </p>
            </div>
          )}
        </Card>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    investment: state.investments.investment,
    loading: state.investments.loading || state.capitalCall.loading,
    closing: state.offerings.closing,
    sending: state.capitalCall.sending,
    user: state.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(CapitalCall);
