import React from 'react';

interface Props {
  handleLinkClick: (segmentTrackName: string, isSecondary: boolean) => void;
}

const ResourceLinks = ({ handleLinkClick }: Props) => (
  <>
    <a
      href="https://cms.eqm-int.com/wp-content/uploads/2024/05/summary-track-record-2Q2024.pdf"
      onClick={() => handleLinkClick('Track Record', true)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="link-text">Track Record</span>
    </a>
    <a
      href="//equitymultiple.com/blog"
      onClick={() => handleLinkClick('Articles', true)}
    >
      <span className="link-text">Articles</span>
    </a>
    <a
      href="//help.equitymultiple.com/en/"
      onClick={() => handleLinkClick('Help Center', true)}
    >
      <span className="link-text">Help Center</span>
    </a>
    <a
      href="//equitymultiple.com/blog/category/investor-stories/"
      onClick={() => handleLinkClick('Testimonials', true)}
    >
      <span className="link-text">Testimonials</span>
    </a>
    <a
      href="//equitymultiple.com/faq"
      onClick={() => handleLinkClick('FAQ', true)}
    >
      <span className="link-text">FAQ</span>
    </a>
    <a
      href="//equitymultiple.com/press"
      onClick={() => handleLinkClick('Press', true)}
    >
      <span className="link-text">Press</span>
    </a>
    <a
      href="//equitymultiple.com/glossary#a"
      onClick={() => handleLinkClick('Glossary', true)}
    >
      <span className="link-text">Glossary</span>
    </a>
  </>
);

export default ResourceLinks;
