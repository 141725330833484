import { Button } from '@equitymultiple/react-eui';
import {
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import { stateOptions } from 'containers/Accounts/contents';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Field } from 'redux-form';

interface Props {
  formName: string;
  previousWizard: () => void;
}

const AddressForm = ({ previousWizard, formName }: Props) => {
  return (
    <div>
      <Row className="margin-row">
        <Col md={6}>
          <Field
            name={formName + '.address'}
            component={ReduxFormInputField}
            label="Address"
          />
        </Col>
        <Col md={6}>
          <Field
            name={formName + '.address2'}
            component={ReduxFormInputField}
            label="Address 2"
            placeholder="Apartment number, etc."
          />
        </Col>
      </Row>
      <Row className="margin-row">
        <Col md={4}>
          <Field
            name={formName + '.city'}
            component={ReduxFormInputField}
            label="City"
          />
        </Col>
        <Col md={4} className="overflow-visible">
          <Field
            name={
              formName +
              (formName === 'investment_account' ? '.region' : '.state')
            }
            id="state"
            component={ReduxFormSelectField}
            label="State"
            options={stateOptions}
          />
        </Col>
        <Col md={4}>
          <Field
            name={formName + '.postal_code'}
            mask="99999"
            component={ReduxFormInputField}
            label="Postal Code"
          />
        </Col>
      </Row>
      <div className="forwardBackButtonWrap">
        <Button type="submit" style={{ width: 240 }}>
          Save Changes
        </Button>
        <button onClick={previousWizard} className="text-link" type="button">
          Back
        </button>
      </div>
    </div>
  );
};

export default AddressForm;
