import { Button, Checkbox, ErrorLabel, Input } from '@equitymultiple/react-eui';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Investment } from 'types/api/investment';
import { numberMaskOptions } from 'utilities/masks';
import utils from 'utilities/utils';

import * as sharedStyles from '../CapitalCallShared.module.scss';
import * as styles from './CapitalCallAllocation.module.scss';

interface Props {
  investment: Investment;
  maxAmount: number;
  onMount: () => void;
  onSubmit: (amount: number) => void;
  sending: boolean;
  transaction: {
    amount: string;
  };
}

const CapitalCallAllocation = ({
  investment,
  maxAmount,
  onMount,
  onSubmit,
  sending,
  transaction
}: Props) => {
  const [acceptedDisclaimer, setAcceptedDisclaimer] = useState(false);
  const [additionalAllocation, setAdditionalAllocation] = useState(null);
  const [errorAmount, setErrorAmount] = useState(null);
  const [errorDisclaimer, setErrorDisclaimer] = useState(false);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const handleSubmit = () => {
    if (!errorAmount) {
      const allocationParsed = additionalAllocation
        ? parseFloat(additionalAllocation)
        : 0;
      setErrorDisclaimer(false);

      if (allocationParsed > 0 && !acceptedDisclaimer) setErrorDisclaimer(true);
      else onSubmit(allocationParsed);
    }
  };

  const handleAllocationChange = value => {
    const val = value ? value.replace(/[^0-9.]/g, '') : 0;

    setAdditionalAllocation(val);

    if (parseFloat(val) > maxAmount) {
      setErrorAmount(
        `The maximum additional allocation is ${utils.formatCurrency(maxAmount)}`
      );
    } else {
      setErrorAmount(null);
    }
  };

  const ccAmount = parseFloat(transaction.amount);
  const newAmount = parseFloat(investment.amount) + ccAmount;
  const projectedAmount = newAmount + parseFloat(additionalAllocation);

  return (
    <div>
      <div className={styles.info}>
        <Row>
          <Col md={4} className={sharedStyles.valueBox}>
            <div className="textLabel">Original Investment</div>
            <p>{utils.formatCurrency(investment.amount)}s</p>
          </Col>
          <Col md={4} className={sharedStyles.valueBox}>
            <div className="textLabel">Capital Call Amount</div>
            <p>{utils.formatCurrency(ccAmount)}</p>
          </Col>
          <Col md={4} className={sharedStyles.valueBox}>
            <div className="textLabel">New Investment</div>
            <p>{utils.formatCurrency(newAmount)}</p>
          </Col>
        </Row>

        <h6 className="margin-top-xx">Capital Call: Additional Allocation</h6>
        <h3>Do you want to further increase your ownership investment?</h3>
        <p>
          On occasion, there is an unclaimed allocation in a capital call. If
          this is the case, you may have the opportunity to increase your
          percentage ownership in the investment by investing more than your pro
          rata share of the capital call. Please note that we cannot guarantee
          the availability of additional allocations, nor can we always meet the
          desired increase in investment. Availability will be determined based
          on the final participation rate of the investor group.
        </p>
      </div>
      <div className={styles.increaseAllocation}>
        <h3>By how much more?</h3>
        <p className="margin-xx">
          If such additional allocation becomes available, I am interested in
          investing the following amount, in addition to my pro rata allocation.
        </p>
        <Row>
          <Col md={4}>
            <div className="textLabel">New Investment Amount</div>
            <p>{utils.formatCurrency(newAmount)}</p>
          </Col>
          <Col md={4}>
            <Input
              label="Additional Allocation"
              id="additional-allocation"
              value={additionalAllocation || ''}
              onChange={e => handleAllocationChange(e.target.value)}
              dollarMask
              inputMaskOptions={numberMaskOptions}
              maxLength={10}
              style={{
                marginBottom: 10
              }}
              allowDecimal
              errorMessage={errorAmount}
            />
            <button
              className="text-link"
              onClick={() => {
                if (maxAmount) setAdditionalAllocation(maxAmount);
                return true;
              }}
              style={{
                display: errorAmount ? 'none' : 'block',
                float: 'right',
                marginBottom: '20px'
              }}
              type="button"
            >
              {maxAmount && utils.formatCurrency(maxAmount)} max
            </button>
          </Col>
          <Col md={4}>
            {additionalAllocation > 0 && (
              <>
                <div className="textLabel">Projected Investment Amount</div>
                <p>{utils.formatCurrency(projectedAmount)}</p>
              </>
            )}
          </Col>
        </Row>
      </div>
      <div className={sharedStyles.submitBox}>
        {additionalAllocation > 0 && (
          <Checkbox
            id="disclaimer"
            name="disclaimer"
            className={sharedStyles.disclaimerCheckbox}
            onChange={() => setAcceptedDisclaimer(!acceptedDisclaimer)}
            checked={acceptedDisclaimer}
            label="I understand that this is an indication of interest for additional allocation only."
          />
        )}

        {errorDisclaimer && (
          <ErrorLabel
            className={sharedStyles.errorMessage}
            message="You must confirm that you stated this as an additional allocation."
          />
        )}
        <Button
          variant="orange"
          className={sharedStyles.submitButton}
          disabled={
            (!acceptedDisclaimer && additionalAllocation > 0) || errorAmount
          }
          loading={sending}
          onClick={handleSubmit}
        >
          {additionalAllocation > 0 ? 'Indicate Interest' : 'Not Interested'}
        </Button>
      </div>
    </div>
  );
};

export default CapitalCallAllocation;
